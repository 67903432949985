<template>
  <div>
    <section class="breadcrumb">
      <a href=""> Loja iFood </a>

      <span> > </span>

      <span> Motopeças </span>
    </section>

    <section class="mb-4">
      <carousel
        :per-page="1"
        :mouse-drag="false"
        :autoplay="true"
        :loop="true"
        :navigationEnabled="false"
        :paginationEnabled="false"
        :autoplayTimeout="5000"
        :autoplayHoverPause="false"
      >
        <slide
          :data-index="item.index"
          :data-name="item.name"
          :key="index"
          v-for="(item, index) in banner.items"
        >
          <!-- @click.prevent="$router.push({ name: 'product-list', params : { categoria: 'BAG' } })" -->
          <img
            class="carouselImage"
            :src="item.url"
            :alt="item.name"
            @click="goToRoute(item)"
          />
          <!--  -->
        </slide>
      </carousel>
    </section>

    <section class="container p-0 mb-4">
      <h1 class="h4 pl-3">Destaques da semana:</h1>
      <div class="container horizontal-scrollable">
        <div class="row no-gutters">
          <div
            class="col-3"
            v-for="(product, index) in filteredProductsList"
            :key="index"
          >
            <div
              class="card"
              @click.prevent="
                goToProduct(
                  product.categoria.descricao,
                  product.descricao,
                  product.id
                )
              "
            >
              <div
                class="card-img"
                v-if="product.imagem[0] && product.imagem[0].link"
                :style="`background-image: url(${ajustarImg(
                  product.imagem[0].link
                )})`"
              ></div>
              <div
                class="card-img"
                v-else
                :style="`background-image: url(${product.imageThumbnail})`"
              ></div>
              <div class="card-content">
                <p class="text-truncate">{{ product.descricao }}</p>
                <h5>
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                  <span style="font-size: 8px"> no Pix </span>
                </h5>
                <small
                  >ou 3 x de
                  {{
                    (product.preco / 3)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container p-0 mb-4">
      <h1 class="h4 pl-3">Compre por Categoria:</h1>

      <div class="container horizontal-scrollable">
        <div class="row no-gutters pb-1">
          <!-- <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/pneus.png" style="width: 100%" />
            </div>
            <p class="mb-0">Pneus e câmaras</p>
          </div>
          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ÓLEOS' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/oleo.png" style="width: 100%" />
            </div>
            <p class="mb-0">Óleo</p>
          </div> -->

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ELÉTRICA' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/eletrica.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Elétrica</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'FREIO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/freio.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Freio</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ILUMINAÇÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/iluminacao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Iluminação</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'MOTOR' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/motor.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Motor</p>
          </div>

          <!-- <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ÓLEOS' }, //ÓLEOS
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/categorias/oleo.png" style="width: 100%" />
            </div>
            <p class="mb-0">Óleo</p>
          </div> -->

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' }, //PNEUS
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/pneus-e-camaras.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Pneus e Câmaras</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'RETROVISORES' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/retrovisor.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Retrovisor</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'SUSPENSÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/suspensao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Suspensão</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'TRANSMISSÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/transmissao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Transmissão</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      banner: [],
      filteredProductsList: {},
    };
  },
  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsListLoading: "productsListLoading",
      getUser: "getUser",
    }),
  },
  methods: {
    ...mapActions({
      fetchProductsList: "fetchProductsList",
      getUserFromIFood: "getUserFromIFood",
      fetchBanner: "fetchBanner",
    }),
    ajustarImg(img) {
      return img.replace("http:", "https:");
    },
    goToRoute(item) {
      if (item.link.includes("https://")) {
        window.location.href = item.link;
      } else {
        if (item.link.includes("product-list")) {
          this.$router.push({
            name: "product-list",
            params: { categoria: item.link.replace("#/product-list/", "") },
          });
        } else {
          this.$router.push({
            name: "product",
            params: { id: item.link.replace("#/product/", "") },
          });
        }
      }
    },

    goToProduct(cat, nam, id) {
      localStorage.setItem("gtpId", id);

      const alt = (x) => {
        return x
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9\s]/g, "")
          .replace(/\s+/g, "-")
          .toLowerCase();
      };

      const newCategory = alt(cat);
      const newName = alt(nam);

      this.$router.push({
        name: "product",
        params: { category: newCategory, name: newName, id: id },
      });
    },

    handleSlideClick() {
      //console.log(dataset.index, dataset.name)
    },
  },

  async beforeCreate() {
    var homeAfterCheckout = localStorage.getItem("ifFac");
    if (homeAfterCheckout === "true") {
      localStorage.setItem("ifFac", "false");
      location.reload();
      return;
    }
  },

  async created() {
    //this.$router.push({ name: "ops" });

    const queryString = decodeURI(window.location.search);
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("public_id")) {
      localStorage.setItem("worker_id", urlParams.get("public_id"));
      try {
        if (urlParams.get('product')) {
          var urlParamProduct = urlParams.get('product')

          this.$router.push({
            name: "product",
            params: { categoria: '', name: '', id: urlParamProduct }
          })
        }

        if (urlParams.get('category')) {
          var urlParamCategory = urlParams.get('category')

          this.$router.push({
            name: "product-list",
            params: { categoria: urlParamCategory, name: '', id: '' }
          })
        }
        
        await this.getUserFromIFood({ user_id: urlParams.get("public_id") });
        this.banner = await this.fetchBanner({
          region: this.getUser.region,
          user_id: urlParams.get("public_id"),
        });
        //this.banner = JSON.parse(this.banner)
      } catch (err) {
        console.error(err);
      }
    }

    this.filteredProductsList = this.productsList.filter((product) =>
      ["PNEUS", "PNEU", "OLEO", "OLEOS", "ÓLEOS"].includes(
        product.categoria.descricao
      )
    );
  },

  async mounted() {
    this.fetchProductsList({
      limit: 50,
      situacao: "Ativo",
      group: "Motopeças",
    });
  },
};
</script>

<style lang="scss" scoped>
// #featured-banner {
// 	&.card {
// 		border: none;
// 		border-radius: 4px;
// 		box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);

// 		img {
// 			border-radius: 4px;
// 		}
// 	}

// 	img {
// 		max-width: 100%;
// 	}
// }

::v-deep .VueCarousel {
  .VueCarousel-navigation,
  .VueCarousel-pagination {
    display: none;
  }
}

.carouselImage {
  border: none;
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
  //box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);
  max-width: 100%;

  @media (max-width: 550px) {
  }
}

.category-img {
  margin: auto;
}

.cat {
  margin-bottom: 25px;

  img {
    width: 100%;
  }
}
</style>
