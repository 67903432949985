var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"mb-4"},[_c('carousel',{attrs:{"per-page":1,"mouse-drag":false,"autoplay":true,"loop":true,"navigationEnabled":false,"paginationEnabled":false,"autoplayTimeout":5000}},_vm._l((_vm.banner.items),function(item,index){return _c('slide',{key:index,attrs:{"data-index":item.index,"data-name":item.name}},[_c('img',{staticClass:"carouselImage",attrs:{"src":item.url,"alt":item.name},on:{"click":function($event){return _vm.goToRoute(item)}}})])}),1)],1),_c('section',{staticClass:"container p-0 mb-4"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Destaques da semana:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.productsList),function(product,index){return _c('div',{key:index,staticClass:"col-3",style:(product.categoria.descricao == 'PNEUS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'PNEU'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEO'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEOS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'ÓLEOS'
              ? 'display: none;'
              : '')},[_c('div',{staticClass:"card",on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
                product.categoria.descricao,
                product.descricao,
                product.id
              )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
                product.imagem[0].link
              )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),0)])]),_c('section',{staticClass:"container p-0 mb-4"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Compre por Categoria:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters pb-1"},[_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'Acessórios' },
            })}}},[_vm._m(0),_c('p',{staticClass:"mb-0"},[_vm._v("Acessórios")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'BAG' }, //BAG
            })}}},[_vm._m(1),_c('p',{staticClass:"mb-0"},[_vm._v("Bag")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'KITS' }, //KITS
            })}}},[_vm._m(2),_c('p',{staticClass:"mb-0"},[_vm._v("Kit")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
            })}}},[_vm._m(3),_c('p',{staticClass:"mb-0"},[_vm._v("Vestuário")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'CALÇADOS' }, //CALÇADOS
            })}}},[_vm._m(4),_c('p',{staticClass:"mb-0"},[_vm._v("Calçados")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/acessorios.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/bag.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/kit.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/vestuario.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/calcados.png")}})])
}]

export { render, staticRenderFns }