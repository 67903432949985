import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2Filters from "vue2-filters";
import VueTheMask from "vue-the-mask";
import VueCarousel from "vue-carousel";
import VueMask from 'v-mask'
import VueGtm from '@gtm-support/vue2-gtm';

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./assets/scss/main.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vue2Filters);
Vue.use(VueTheMask);
Vue.use(VueCarousel);
Vue.use(VueMask);

Vue.use(VueGtm, {
  id: 'GTM-PQDDTWWK',
  debug: false, 
  loadScript: true, 
})

Vue.config.productionTip = false;

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
