<template>
  <div>
    <section class="mb-4">
      <carousel
        :per-page="1"
        :mouse-drag="false"
        :autoplay="true"
        :loop="true"
        :navigationEnabled="false"
        :paginationEnabled="false"
        :autoplayTimeout="5000"
      >
        <slide
          :data-index="item.index"
          :data-name="item.name"
          :key="index"
          v-for="(item, index) in banner.items"
        >
          <!-- @click.prevent="$router.push({ name: 'product-list', params : { categoria: 'BAG' } })" -->
          <img
            class="carouselImage"
            :src="item.url"
            :alt="item.name"
            @click="goToRoute(item)"
          />
          <!--  -->
        </slide>
      </carousel>
    </section>

    <section class="cat">
      <div class="container">
        <div class="row">
          <div class="col">
            <img
              src="@/assets/img/roupas-e-acessorios.png"
              @click.prevent="
                $router.push({
                  path: 'roupas-e-acessorios',
                })
              "
            />
          </div>

          <div class="col">
            <img
              src="@/assets/img/motopecas.png"
              @click.prevent="
                $router.push({
                  path: 'motopecas',
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <section class="container p-0 cpc">
      <h1 class="h4 pl-3">Compre por Categoria:</h1>

      <div class="container horizontal-scrollable">
        <div class="row no-gutters pb-1">
          <!-- <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/pneus.png" style="width: 100%" />
            </div>
            <p class="mb-0">Pneus e câmaras</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ÓLEOS' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/oleo.png" style="width: 100%" />
            </div>
            <p class="mb-0">Óleo</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'BAG' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/Bag.png" style="width: 100%" />
            </div>
            <p class="mb-0">Bag</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'VESTUÁRIO' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/Vestuario.png" style="width: 100%" />
            </div>
            <p class="mb-0">Vestuário</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'KITS' },
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/Kits.png" style="width: 100%" />
            </div>
            <p class="mb-0">Kit</p>
          </div> -->

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ACESSÓRIOS' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/acessorios.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Acessórios</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'BAG' }, //BAG
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/categorias/bag.png" style="width: 100%" />
            </div>
            <p class="mb-0">Bag</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ELÉTRICA' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/eletrica.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Elétrica</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'FREIO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/freio.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Freio</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ILUMINAÇÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/iluminacao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Iluminação</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'KITS' }, //KITS
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/categorias/kit.png" style="width: 100%" />
            </div>
            <p class="mb-0">Kit</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'MOTOR' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/motor.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Motor</p>
          </div>

          <!-- <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'ÓLEOS' }, //ÓLEOS
              })
            "
          >
            <div class="category-img">
              <img src="@/assets/img/categorias/oleo.png" style="width: 100%" />
            </div>
            <p class="mb-0">Óleo</p>
          </div> -->

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'PNEUS' }, //PNEUS
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/pneus-e-camaras.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Pneus e Câmaras</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'RETROVISORES' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/retrovisor.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Retrovisor</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'SUSPENSÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/suspensao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Suspensão</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'TRANSMISSÃO' },
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/transmissao.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Transmissão</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/vestuario.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Vestuário</p>
          </div>

          <div
            class="col-2"
            @click.prevent="
              $router.push({
                name: 'product-list',
                params: { categoria: 'CALÇADOS' }, //CALÇADOS
              })
            "
          >
            <div class="category-img">
              <img
                src="@/assets/img/categorias/calcados.png"
                style="width: 100%"
              />
            </div>
            <p class="mb-0">Calçados</p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="container p-0 mb-4"
      v-if="
        productsList.some(
          (item) =>
            item.product &&
            item.product.categoria &&
            item.product.categoria?.descricao &&
            (item.product.categoria?.descricao?.includes('ÓLEO') ||
              item.product.categoria?.descricao?.includes('PNEUS'))
        )
      "
    >
      <h1 class="h4 pl-3">Destaques em Motopeças:</h1>
      <div class="container horizontal-scrollable">
        <div class="row no-gutters">
          <div
            class="col-3"
            v-for="(product, index) in productsList"
            :key="index"
          >
            <div
              :style="
                product.categoria.descricao == 'PNEUS'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'PNEU'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'OLEO'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'OLEOS'
                  ? ''
                  : 'display: none;' || product.categoria.descricao == 'ÓLEOS'
                  ? ''
                  : 'display: none;'
              "
              class="card"
              @click.prevent="
                goToProduct(
                  product.categoria.descricao,
                  product.descricao,
                  product.id
                )
              "
            >
              <div
                class="card-img"
                v-if="product.imagem[0] && product.imagem[0].link"
                :style="`background-image: url(${ajustarImg(
                  product.imagem[0].link
                )})`"
              ></div>
              <div
                class="card-img"
                v-else
                :style="`background-image: url(${product.imageThumbnail})`"
              ></div>
              <div class="card-content">
                <p class="text-truncate">{{ product.descricao }}</p>
                <h5>
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                  <span style="font-size: 8px"> no Pix </span>
                </h5>
                <small
                  >ou 3 x de
                  {{
                    (product.preco / 3)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container p-0 mb-4">
      <h1 class="h4 pl-3">Produtos em Destaque:</h1>
      <div class="container horizontal-scrollable">
        <div class="row no-gutters">
          <div
            class="col-3"
            :style="
              product.categoria.descricao == 'PNEUS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'PNEU'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEO'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEOS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'ÓLEOS'
                ? 'display: none;'
                : ''
            "
            v-for="(product, index) in productsList"
            :key="index"
          >
            <div
              :style="
                product.categoria.descricao == 'PNEUS'
                  ? 'display: none;'
                  : '' || product.categoria.descricao == 'PNEU'
                  ? 'display: none;'
                  : '' || product.categoria.descricao == 'OLEO'
                  ? 'display: none;'
                  : '' || product.categoria.descricao == 'OLEOS'
                  ? 'display: none;'
                  : '' || product.categoria.descricao == 'ÓLEOS'
                  ? 'display: none;'
                  : ''
              "
              class="card"
              @click.prevent="
                goToProduct(
                  product.categoria.descricao,
                  product.descricao,
                  product.id
                )
              "
            >
              <div
                class="card-img"
                v-if="product.imagem[0] && product.imagem[0].link"
                :style="`background-image: url(${ajustarImg(
                  product.imagem[0].link
                )})`"
              ></div>
              <div
                class="card-img"
                v-else
                :style="`background-image: url(${product.imageThumbnail})`"
              ></div>
              <div class="card-content">
                <p class="text-truncate">{{ product.descricao }}</p>
                <h5>
                  {{
                    ((product.preco / 100) * 95)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}
                  <span style="font-size: 8px"> no Pix </span>
                </h5>
                <small
                  >ou 3 x de
                  {{
                    (product.preco / 3)
                      | currency("R$ ", 2, {
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      })
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      banner: [],
    };
  },
  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsListLoading: "productsListLoading",
      getUser: "getUser",
    }),
  },
  methods: {
    ...mapActions({
      fetchProductsList: "fetchProductsList",
      getUserFromIFood: "getUserFromIFood",
      fetchBanner: "fetchBanner",
    }),
    ajustarImg(img) {
      return img.replace("http:", "https:");
    },
    goToRoute(item) {
      if (item.link.includes("https://")) {
        window.location.href = item.link;
      } else {
        if (item.link.includes("product-list")) {
          this.$router.push({
            name: "product-list",
            params: { categoria: item.link.replace("#/product-list/", "") },
          });
        } else {
          if (item.link.includes("product"))
            this.$router.push({
              name: "product",
              params: { id: item.link.replace("#/product/", "") },
            });
          else
            this.$router.push({
              path: item.link.replace("#/", ""),
            });
        }
      }
    },

    goToProduct(cat, nam, id) {
      localStorage.setItem("gtpId", id);

      const alt = (x) => {
        return x
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9\s]/g, "")
          .replace(/\s+/g, "-")
          .toLowerCase();
      };

      const newCategory = alt(cat);
      const newName = alt(nam);

      this.$router.push({
        name: "product",
        params: { category: newCategory, name: newName, id: id },
      });
    },
  },

  async beforeCreate() {
    var homeAfterCheckout = localStorage.getItem("ifFac");
    if (homeAfterCheckout === "true") {
      localStorage.setItem("ifFac", "false");
      location.reload();
      return;
    }
  },

  async created() {
    const queryString = decodeURI(window.location.search);
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("public_id")) {
      localStorage.setItem("worker_id", urlParams.get("public_id"));

      try {
        await this.getUserFromIFood({ user_id: urlParams.get("public_id") });

        const productId = urlParams.get("product");
        const categoryId = urlParams.get("category");

        if (productId) {
          this.$router.replace({
            name: "product",
            params: { categoria: "", name: "", id: productId },
          });
        } else if (categoryId) {
          this.$router.replace({
            name: "product-list",
            params: { categoria: categoryId, name: "", id: "" },
          });
        } else {
          this.banner = await this.fetchBanner({
            region: this.getUser.region,
            user_id: urlParams.get("public_id"),
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  },

  async mounted() {
    this.fetchProductsList({ limit: 25, situacao: "Ativo" });
  },
};
</script>

<style lang="scss" scoped>
// #featured-banner {
// 	&.card {
// 		border: none;
// 		border-radius: 4px;
// 		box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);

// 		img {
// 			border-radius: 4px;
// 		}
// 	}

// 	img {
// 		max-width: 100%;
// 	}
// }

::v-deep .VueCarousel {
  .VueCarousel-navigation,
  .VueCarousel-pagination {
    display: none;
  }
}

.carouselImage {
  border: none;
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
  //box-shadow: 0 3px 6px 6px rgba(0, 0, 0, 0.16);
  max-width: 100%;

  @media (max-width: 550px) {
  }
}

.category-img {
  margin: auto;
}

.cat {
  margin-bottom: 25px;

  img {
    width: 100%;
  }
}

.cpc {
  .horizontal-scrollable {
    .col-2 {
      margin-right: 2px;
    }
  }
}
</style>
