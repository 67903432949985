var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"mb-4"},[_c('carousel',{attrs:{"per-page":1,"mouse-drag":false,"autoplay":true,"loop":true,"navigationEnabled":false,"paginationEnabled":false,"autoplayTimeout":5000}},_vm._l((_vm.banner.items),function(item,index){return _c('slide',{key:index,attrs:{"data-index":item.index,"data-name":item.name}},[_c('img',{staticClass:"carouselImage",attrs:{"src":item.url,"alt":item.name},on:{"click":function($event){return _vm.goToRoute(item)}}})])}),1)],1),_c('section',{staticClass:"cat"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('img',{attrs:{"src":require("@/assets/img/roupas-e-acessorios.png")},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                path: 'roupas-e-acessorios',
              })}}})]),_c('div',{staticClass:"col"},[_c('img',{attrs:{"src":require("@/assets/img/motopecas.png")},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                path: 'motopecas',
              })}}})])])])]),_c('section',{staticClass:"container p-0 cpc"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Compre por Categoria:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters pb-1"},[_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'ACESSÓRIOS' },
            })}}},[_vm._m(0),_c('p',{staticClass:"mb-0"},[_vm._v("Acessórios")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'BAG' }, //BAG
            })}}},[_vm._m(1),_c('p',{staticClass:"mb-0"},[_vm._v("Bag")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'ELÉTRICA' },
            })}}},[_vm._m(2),_c('p',{staticClass:"mb-0"},[_vm._v("Elétrica")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'FREIO' },
            })}}},[_vm._m(3),_c('p',{staticClass:"mb-0"},[_vm._v("Freio")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'ILUMINAÇÃO' },
            })}}},[_vm._m(4),_c('p',{staticClass:"mb-0"},[_vm._v("Iluminação")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'KITS' }, //KITS
            })}}},[_vm._m(5),_c('p',{staticClass:"mb-0"},[_vm._v("Kit")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'MOTOR' },
            })}}},[_vm._m(6),_c('p',{staticClass:"mb-0"},[_vm._v("Motor")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'PNEUS' }, //PNEUS
            })}}},[_vm._m(7),_c('p',{staticClass:"mb-0"},[_vm._v("Pneus e Câmaras")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'RETROVISORES' },
            })}}},[_vm._m(8),_c('p',{staticClass:"mb-0"},[_vm._v("Retrovisor")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'SUSPENSÃO' },
            })}}},[_vm._m(9),_c('p',{staticClass:"mb-0"},[_vm._v("Suspensão")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'TRANSMISSÃO' },
            })}}},[_vm._m(10),_c('p',{staticClass:"mb-0"},[_vm._v("Transmissão")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'VESTUÁRIO' }, //VESTUÁRIO
            })}}},[_vm._m(11),_c('p',{staticClass:"mb-0"},[_vm._v("Vestuário")])]),_c('div',{staticClass:"col-2",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'product-list',
              params: { categoria: 'CALÇADOS' }, //CALÇADOS
            })}}},[_vm._m(12),_c('p',{staticClass:"mb-0"},[_vm._v("Calçados")])])])])]),(
      _vm.productsList.some(
        (item) =>
          item.product &&
          item.product.categoria &&
          item.product.categoria?.descricao &&
          (item.product.categoria?.descricao?.includes('ÓLEO') ||
            item.product.categoria?.descricao?.includes('PNEUS'))
      )
    )?_c('section',{staticClass:"container p-0 mb-4"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Destaques em Motopeças:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.productsList),function(product,index){return _c('div',{key:index,staticClass:"col-3"},[_c('div',{staticClass:"card",style:(product.categoria.descricao == 'PNEUS'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'PNEU'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'OLEO'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'OLEOS'
                ? ''
                : 'display: none;' || product.categoria.descricao == 'ÓLEOS'
                ? ''
                : 'display: none;'),on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
                product.categoria.descricao,
                product.descricao,
                product.id
              )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
                product.imagem[0].link
              )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),0)])]):_vm._e(),_c('section',{staticClass:"container p-0 mb-4"},[_c('h1',{staticClass:"h4 pl-3"},[_vm._v("Produtos em Destaque:")]),_c('div',{staticClass:"container horizontal-scrollable"},[_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.productsList),function(product,index){return _c('div',{key:index,staticClass:"col-3",style:(product.categoria.descricao == 'PNEUS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'PNEU'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEO'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'OLEOS'
              ? 'display: none;'
              : '' || product.categoria.descricao == 'ÓLEOS'
              ? 'display: none;'
              : '')},[_c('div',{staticClass:"card",style:(product.categoria.descricao == 'PNEUS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'PNEU'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEO'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'OLEOS'
                ? 'display: none;'
                : '' || product.categoria.descricao == 'ÓLEOS'
                ? 'display: none;'
                : ''),on:{"click":function($event){$event.preventDefault();return _vm.goToProduct(
                product.categoria.descricao,
                product.descricao,
                product.id
              )}}},[(product.imagem[0] && product.imagem[0].link)?_c('div',{staticClass:"card-img",style:(`background-image: url(${_vm.ajustarImg(
                product.imagem[0].link
              )})`)}):_c('div',{staticClass:"card-img",style:(`background-image: url(${product.imageThumbnail})`)}),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(product.descricao))]),_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(((product.preco / 100) * 95),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", }))+" "),_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(" no Pix ")])]),_c('small',[_vm._v("ou 3 x de "+_vm._s(_vm._f("currency")((product.preco / 3),"R$ ", 2, { thousandsSeparator: ".", decimalSeparator: ",", })))])])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/acessorios.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/bag.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/eletrica.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/freio.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/iluminacao.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/kit.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/motor.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/pneus-e-camaras.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/retrovisor.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/suspensao.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/transmissao.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/vestuario.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/categorias/calcados.png")}})])
}]

export { render, staticRenderFns }