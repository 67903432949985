import axios from "axios";
// import store from "../store/index";
let baseURL;

console.log(location.hostname)

switch (location.hostname) {
  case "localhost" :
	// baseURL = "https://api.lojadoentregadorifood.com.br/";
	baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
	break;
  case "lojadoentregadorifood.com.br":
    baseURL = "https://api.lojadoentregadorifood.com.br/";
    break;
  default:
    baseURL = "https://api-dev.lojadoentregadorifood.com.br/";
    break;
}

const http = axios.create({
	baseURL: baseURL//process.env.VUE_APP_API,
});

// if (store.getters["auth/isAuthenticated"]) {
// 	http.interceptors.request.use(
// 		async (config) => {
// 				config.headers["Authorization"] = `${store.getters["auth/token"]}`;
// 				return config;
// 			},
// 			(error) => Promise.reject(error)
// 	);
// }

const urlParams = new URLSearchParams(window.location.search);
const publicId = urlParams.get("public_id");

http.interceptors.request.use(
  async (config) => {
    if (publicId) {
      config.headers["Authorization"] = publicId;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
	(response) => response,
	(error) => {
		return Promise.reject(error);
	}
);

export {
	http
};